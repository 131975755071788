<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
  >
    <v-select
      v-model="field.value"
      :hint="hint"
      :items="uniState"
      :menu-props="{maxHeight:'250'}"
      item-text="statename"
      item-value="idstate"
      v-bind="field.props"
      dense
      box
      label="Select university`s state"
      validate-on-blur
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'
import { createGetParams } from '@/rest'

export default {
  name: 'StateProviceField',
  mixins: [formField],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      uniState: [],
      hint: 'Select University`s State',
    }
  },
  mounted: function () {
    this.getStates()
  },
  methods: {
    getStates: function () {
      this.$rest.get('getUniversityState.php', createGetParams({}))
        .then(function (response) {
          for (const item of response.data) {
            this.uniState.push(item)
          }
        }.bind(this)).catch(() => {
          // ignore
        })
    },
  },
}
</script>
